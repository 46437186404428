
































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LIconDefault, LPolygon } from 'vue2-leaflet';
import {
	REGION_REQUEST,
	REGION_LOADED,
    MAP_CREATE,
} from "@/store/actions/main";
import leafletImage from 'leaflet-image';

@Component({
    components: {
		"l-map": LMap,
		"l-tile-layer": LTileLayer,
		"l-marker": LMarker,
		"v-icondefault": LIconDefault,
        "l-polygon": LPolygon,
	},
})
export default class MapsForm extends Vue {
    

    public convertedForm: any = new FormData();
    public dvachFlags = [false, false, false];

    //map
    private mapUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    private lightMapUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";
    private blueMapUrl = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png";
    private darkMapUrl = "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
    private center = [59.95, 30.3];
	private zoom = 11;
    private bounds: any;
    private mapLoading: boolean = true;
    //form
    private form: any = {};
    private regions: any = [];
    //other
    private loading: boolean = true;
    private mapLocked: boolean = false;
    private canvasSize: any = {w: 800, h: 600};
    private scrollbarSettings = {
		suppressScrollY: false,
		suppressScrollX: false,
		wheelPropagation: false,
	};
    //polygon
    private polygon: any = {latlngs:[], color: "blue"};
    private previewPolygon: any = {latlngs:[], color: "lightskyblue"};
    private polygonColor = "blue";
    private previewColor = "lightskyblue";
    private editingPolygon: boolean = false;

    $refs!: 
    {
        mainMap: any,
        lightMap: any,
        blueMap: any,
        darkMap: any,
        lightMapL: any,
        blueMapL: any,
        darkMapL: any,
        canvas: any,
    }
    //Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];

		needReload.push(
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		);
		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;

		if (this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED)) {
			this.$store.dispatch(REGION_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
    private initSelf(){
        this.regions = this.$store.getters.REGION_TABLE.data;
        this.form.region = this.$route.params.id;
        this.loading = false;
        console.log("init maps form");
    }
    //methods
    private editPolygonStart(){
        this.editingPolygon = true;
        //this.lockMap();
    }
    private editPolygonEnd(){
        this.editingPolygon = false;
        this.previewPolygon = {latlngs:[], color: this.previewColor};
    }
    private deletePolygon(){
        this.polygon = {latlngs:[], color: this.polygonColor};
        this.previewPolygon = {latlngs:[], color: this.previewColor};
        this.editingPolygon = false;
        //this.unlockMap();
    }
    private lockMap(){
        this.mapLocked = true;
        console.log("lock map");
        let map = this.$refs.mainMap.mapObject;
        map.dragging.disable();
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        //if (map.tap) map.tap.disable();
            //document.getElementById('mainMap').style.cursor='default';
    }
    private unlockMap(){
        this.mapLocked = false;
        console.log("unlock map");
        let map = this.$refs.mainMap.mapObject;
        map.dragging.enable();
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        map.scrollWheelZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        //if (map.tap) map.tap.enable();
        //document.getElementById('mainMap').style.cursor='grab';
    }
    private popPolygon(){
        if(this.polygon.latlngs.length > 0){
            this.polygon.latlngs.pop();
        }
    }
    private fillPreviewPolygon(e){
        if(this.polygon.latlngs.length > 0){
            this.previewPolygon.latlngs.length = 0;
            var arr = [...this.polygon.latlngs];
            this.previewPolygon.color = this.previewColor;
            this.previewPolygon.latlngs.push(arr[0]);
            this.previewPolygon.latlngs.push(e.latlng);
            if(arr.length > 1)
                this.previewPolygon.latlngs.push(arr[arr.length - 1]);
        }
    }
    private updateBounds(){
        console.log("bounds updated");
        this.form.lon_0 = this.bounds._northEast.lng;
        this.form.lat_0 = this.bounds._northEast.lat;
        this.form.lon_1 = this.bounds._southWest.lng;
        this.form.lat_1 = this.bounds._southWest.lat;
        console.log("lon0",this.form.lon_0);
        console.log("lat0",this.form.lat_0);
        console.log("lon1",this.form.lon_1);
        console.log("lat1",this.form.lat_1);
        
    }
    private isBoundsLoaded(){
        if(typeof this.bounds == 'undefined')
            return false;
        return true;
    }
    private latLngToCoords(lng0, lat0, lng1, lat1, lng, lat, w, h){
        let lngW = lng0 - lng1;
        let latH = lat0 - lat1;
        let lngDotW = lng0 - lng;
        let latDotH = lat0 - lat;
        let lngPercent = lngDotW / lngW;
        let latPercent = latDotH / latH;
        let x = w - w * lngPercent;
        let y = h * latPercent;
        return {x: x, y: y};
    }
    private addMap(){
		//convertedMap.append('image', this.form.image);
		//var uploaded_photo = this.$refs.myVueDropzone.getAcceptedFiles()[0];
        this.convertedForm = new FormData();
		this.convertedForm.append("lon_0", this.form.lon_0);
		this.convertedForm.append("lat_0", this.form.lat_0);
        this.convertedForm.append("lon_1", this.form.lon_1);
        this.convertedForm.append("lat_1", this.form.lat_1);

        let map;
        let latlngs = this.polygon.latlngs;
        console.log("lngs:",latlngs);
        let conv = this.latLngToCoords;
        let KALL = this;
        let f = this.form;
        let w = 800;
        let h = 600;
        for(let j = 0; j < 3; j++){
            if(j == 0){
                map = this.$refs.lightMapL.mapObject;
            }
                
            if(j == 1){
                map = this.$refs.blueMapL.mapObject;
            }
                
            if(j == 2){
                map = this.$refs.darkMapL.mapObject;
            }
                
            var myfun = function(err, canvas) {
                let ctx = canvas.getContext('2d');
                var maskCanvas = document.createElement('canvas');
                maskCanvas.width = canvas.width;
                maskCanvas.height = canvas.height;
                let mctx = maskCanvas.getContext('2d') as any;
                mctx.fillStyle = "white";
                mctx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
                mctx.globalCompositeOperation = 'xor';
                var p = new Path2D();
                var coords = conv(f.lon_0, f.lat_0, f.lon_1, f.lat_1, latlngs[0].lng, latlngs[0].lat, w, h);
                p.moveTo(coords.x, coords.y);
                for(let i = 0; i <= latlngs.length; i++){
                    if(i < latlngs.length){
                        coords = conv(f.lon_0, f.lat_0, f.lon_1, f.lat_1, latlngs[i].lng, latlngs[i].lat, w, h);
                        console.log("coords:",coords);
                        p.lineTo(coords.x, coords.y);
                    }
                    else{
                        coords = conv(f.lon_0, f.lat_0, f.lon_1, f.lat_1, latlngs[0].lng, latlngs[0].lat, w, h);
                        console.log("fcoords:",coords);
                        p.lineTo(coords.x, coords.y);
                    }
                }
                mctx.fill(p);
                ctx.drawImage(maskCanvas, 0, 0);
                console.log("---KAAAAAL---");
                let keys = ["image_white", "image_blue", "image_dark"];
                KALL.convertedForm.append(keys[j], canvas.toDataURL() as String);
                //KALL.dvachFlags[j] = true;
                //KALL.sendMaps();
                //convertedMap.append("images", canvas.toDataURL());
                //this.myObj.push(canvas.toDataURL());
                //console.log("append map", canvas.toDataURL());
                /*
                canvas.toBlob(function(blob) {
                convertedMap.append('images', blob);
                console.log("append map", blob);
                });
                */
                
            }
            leafletImage(map, myfun);
        }
        console.log("form",this.form);
        localStorage.setItem("regionId", this.form.region);
        setTimeout(()=>{this.$store.dispatch(MAP_CREATE, KALL.convertedForm).then(() => {});},2000);
    }
    //Events
    private mapReady(){
        //moving pos a bit to update additional maps state
        this.center = [59.95, 30.301];
        this.mapLoading = false;
        
    }
    private mapClick(e){
        console.log(e);
        if(this.editingPolygon){
            this.polygon.latlngs.push(e.latlng);
            this.fillPreviewPolygon(e);
        }
    }
    private mapMouseMove(e){
        if(this.editingPolygon){
            this.fillPreviewPolygon(e);
        }
    }
    //Style
    /*
    private getLayer(bool){
        if(bool)
            return "z-index: 1010;";
        else
            return "z-undex: 1000;";
    }
    */
    private sendMaps(){
        console.log("sendMaps");
        console.log(this.dvachFlags);
        if(this.dvachFlags.length < 3)
            return;
        this.dvachFlags.forEach(el => {
            if(!el)
                return;
        });
        this.$store.dispatch(MAP_CREATE, this.convertedForm).then(() => {});
    }
    //Hooks
    mounted() {
        this.tryToInitSelf();
    }
    @Watch('dvachFlags')
    onPropertyChanged(value: Array<boolean>, oldValue: Array<boolean>) {
        console.log("dvachFlags changed");
        if(value.length < 3)
            return;
        value.forEach(el => {
            if(!el)
                return;
        });
        this.sendMaps();
    }
}
